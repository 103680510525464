import { Accordion } from 'components/_universal/Accordion/Accordion';
import * as Common from 'components/_universal/Common';
import React from 'react';
import { CookiesTable } from 'sections/PrivacyPolicy/CookiesTable';
import { ROUTE_HOME } from 'shared/paths';
import { GDPRIcon } from 'shared/svg/gdpr';
import { StyledExternalLink } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import {
    ContentWrapper,
    Line,
    SectionTitile,
    UpdateText,
    Wrapper,
} from './PrivacyPolicy.styled';

export const PrivacyPolicy = () => {
    const isXs = useBreakpoint('xs');
    return (
        <Wrapper>
            <Common.Div flex={isXs ? 'column' : 'row'} gap="16px">
                <GDPRIcon />
                <SectionTitile>
                    General Data Protection Regulation
                </SectionTitile>
            </Common.Div>

            <ContentWrapper>
                <Accordion isOpen number={1} title={'General Information'}>
                    This Privacy Policy includes information on processing of
                    personal data and other information related to users of{' '}
                    <StyledExternalLink href={ROUTE_HOME}>
                        www.codahead.com
                    </StyledExternalLink>
                    (hereinafter referred to as “Website”), including the
                    information required by the Regulation No 2016/679 of 27
                    April 2016 of the European Parliament and of the Council on
                    the protection of individuals with regard to the processing
                    of data and on the free movement of such data and the repeal
                    of Directive 96/46/WE (hereinafter referred to as “RODO”).
                </Accordion>
                <Accordion number={2} title={'Personal data administrator'}>
                    The personal data administrator is Codahead – limited
                    liability company (abbreviated sp. z o.o), limited
                    partnership based in Kraków. The administrator may be
                    contacted by: <br />
                    <ul>
                        <li>
                            Company’s address: 30-363 Kraków, ul. Rydlówka 20;
                        </li>
                        <li>
                            Email address:{' '}
                            <a href="mailto:gdpr@codahead.com">
                                gdpr@codahead.com
                            </a>
                        </li>
                    </ul>
                </Accordion>
                <Accordion
                    number={3}
                    title={
                        'Objectives, legal basis and   period of data processing – contact form'
                    }
                >
                    The administrator processes your personal data only if you
                    use the contact form placed on the Website. In the situation
                    referred to in provision of clause 1, the administrator
                    processes the following personal data: <br />
                    <ul>
                        <li>
                            name (optionally also your surname, if provided)
                        </li>
                        <li>email address</li>
                        <li>IP address</li>
                        <li>the content of correspondence.</li>
                    </ul>
                    <br />
                    The personal data, referred to in provision of clause 2 are
                    processed for the purpose of:
                    <ul>
                        <li>
                            responding to your inquiry, dealing with the case
                            you submitted via the contact form, sending
                            marketing information on the activities you wanted
                            to receive, as you have given your consent (Article
                            6(1) (a) RODO), taking into consideration that these
                            data will be processed for the purposes set out
                            above for a period not exceeding that necessary for
                            the purpose for which they were supplied; and
                        </li>
                        <li>
                            establishing, investigating and/or defending from
                            any of your claims, what constitutes a legitimate
                            interest of the administrator (Article 6(1) (f) ),
                            taking into consideration that these data will be
                            processed for the purposes set out above only until
                            the relevant investigation or prosecution is
                            concluded, or in case when such proceedings have not
                            been initiated before the end of the limitation
                            period – up to the time limit set for the limitation
                            period.
                        </li>
                        <li>
                            Providing personal data, referred to in provision of
                            clause 2, is voluntary, but it is necessary to
                            provide answers to your questions, or to deal with
                            your case.
                        </li>
                        <li>
                            The consent, referred to in provision of clause
                            3(a), may be withdrawn (at any time and without
                            giving reasons) after a request is made by a data
                            subject, however, this does not affect the legality
                            of data processing carried out before the withdrawal
                            of the consent.
                        </li>
                    </ul>
                </Accordion>
                <Accordion
                    number={4}
                    title={
                        'Objectives, legal basis and period of data processing – recruitment form'
                    }
                >
                    <ul>
                        <li>
                            The administrator processes your personal data only
                            if you apply for a job with the recruitment form
                            placed on the Website.
                        </li>
                        <li>
                            In the situation referred to in provision of clause
                            1, the administrator processes all personal data
                            that you entered in the recruitment form.
                        </li>
                        <li>
                            The personal data, referred to in provision of
                            clause 2 are processed for the purpose of: <br />
                            <ul>
                                <li>
                                    taking part in the recruitment process for
                                    the job position you are applying to, as you
                                    have given your consent (Article 6(1) (a)
                                    RODO), taking into consideration that these
                                    data will be processed for the period
                                    necessary to conduct the recruitment process
                                    and signing the job agreement with
                                    appropriate candidate; and
                                </li>
                                <li>
                                    establishing, investigating and/or defending
                                    from any of your claims, what constitutes a
                                    legitimate interest of the administrator
                                    (Article 6(1) (f) ), taking into
                                    consideration that these data will be
                                    processed for the purposes set out above
                                    only until the relevant investigation or
                                    prosecution is concluded, or in case when
                                    such proceedings have not been initiated
                                    before the end of the limitation period – up
                                    to the time limit set for the limitation
                                    period.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Further, if you voluntarily agree to a separate
                            consent for the processing of your personal data for
                            future recruitment processes, your personal data
                            (referred to in provision of clause 2) will be
                            processed by the administrator also for the purpose
                            of future recruitment for one year from the day the
                            administrator receives these data.
                        </li>
                        <li>
                            To give the consent (referred to in provision of
                            clause 4) add in your CV the following clause: “I
                            hereby agree to the processing by Codahead – limited
                            liability company (abbreviated sp. z o.o), limited
                            partnership based in Kraków of my personal data
                            included in the recruitment form for the future
                            recruitment processes.”
                        </li>
                        <li>
                            The consent, referred to in provision of clause 3(a)
                            and clause 4, may be withdrawn (at any time and
                            without giving reasons) after a request is made by a
                            data subject, however, this does not affect the
                            legality of data processing carried out before the
                            withdrawal of the consent.
                        </li>
                    </ul>
                </Accordion>
                <Accordion
                    number={5}
                    title={
                        'Information and marketing actions taken by the administrator'
                    }
                >
                    The Codahead Website is a place where the administrator
                    publishes marketing materials about products and services
                    provided. Displaying marketing materials to users is done by
                    the administrator on the basis of his legitimate interest
                    (Article 6(1) (f) RODO). At the same time, this action is
                    without prejudice to the rights and freedoms of users,
                    because users expect receiving messages of corresponding
                    content, sometimes even they wish to, or visit the Website
                    in order to receive such messages.
                </Accordion>
                <Accordion number={6} title={'Recipients of the personal data'}>
                    Your personal data, referred to in Article 3 (2) and Article
                    4 (2), are distributed solely to: authorised personnel and
                    company’s collaborators, and/or entities with whom the
                    administrator have concluded the necessary agreements on
                    processing of personal data, providers who manage hosting
                    and operations of IT infrastructure, recruitment services,
                    CRM providers, providers of cloud computing services.
                </Accordion>
                <Accordion
                    number={7}
                    title={'Personal data transfers to third countries'}
                >
                    We may transfer your personal data to the United State,
                    where our servers are located. The owners of the servers are
                    active members of “Privacy Shield”, which according to
                    European Commission ensures an adequate level of protection
                    for personal data. Therefore, such transfer is pursuant to
                    Art. 46 of RODO.
                </Accordion>
                <Accordion number={8} title={'Personal data subject’s rights'}>
                    Any individual with regard to their personal data, has the
                    right to: <br />
                    <ul>
                        <li>
                            access their personal data – has a right to know
                            whether their personal data is processed by the
                            administrator, and if it is – how, for how long, and
                            what is the purpose of processing
                        </li>
                        <li>
                            receive a copy of their personal data – individual
                            has possibility to submit a request for a copy of
                            their personal data, and the administrator must
                            respond to that request within 1 month and deliver a
                            copy of processed personal data to that person. The
                            first copy is delivered free of charge, and every
                            subsequent copy might be charged reasonable
                            subsistence for administrative costs;
                        </li>
                        <li>
                            amend personal data content – individual can demand
                            that their personal data is corrected with no delay,
                            and in some certain cases – request complement the
                            data
                        </li>
                        <li>
                            delete their personal data (to be forgotten) –
                            individual can demand that their personal data is
                            deleted, and the administrator is bound to meet this
                            need without delay, as appropriate, if there are not
                            any reasons against described in RODO, which might
                            enable the administrator to reject the demand;
                        </li>
                        <li>
                            have the data processing restricted – the
                            administrator restricts processing if: <br />
                            <ul>
                                <li>
                                    their accuracy is denied by the data subject
                                    – processing will be restricted for the
                                    period that allows the administrator to
                                    verify the correctness of the data
                                </li>
                                <li>
                                    their processing is unlawful, while complete
                                    deletion is not possible
                                </li>
                                <li>
                                    there is no need to process the data, or
                                    processing is necessary solely to exercise
                                    or defence legal claims of the data subject;
                                </li>
                                <li>
                                    the data subject raised a formal objection,
                                    referred to in Article 21 (1) of RODO –
                                    restriction of processing will last until
                                    proving the relevance of the objection;{' '}
                                </li>
                                <li>
                                    transfer personal data – individual can
                                    request that their personal data is
                                    transferred to another administrator. The
                                    current administrator must transfer the data
                                    to new administrator in a machine-readable
                                    form. The right to transfer personal data
                                    refers only to the data gathered for the
                                    implementation of the agreement, or on the
                                    basis of the explicit consent of the data
                                    subject;
                                </li>
                            </ul>
                        </li>
                        <li>
                            raise objections against the processing of their
                            personal data – the data subject can raise an
                            objection: <br />
                            <ul>
                                <li>
                                    against processing of personal data for the
                                    purpose of direct marketing, including
                                    profiling, at any time – to the extent that
                                    processing is related to direct marketing.
                                    If the data subject raises an objection
                                    against the processing of their personal
                                    data for the purpose of direct marketing,
                                    the administrator can no longer process
                                    their data for that purpose;
                                </li>
                                <li>
                                    against processing of personal data, for
                                    reasons caused by their specific situation,
                                    including profiling, of which the legal
                                    basis is: <br />
                                    <ul>
                                        <li>
                                            necessity of processing to perform a
                                            task carried out in the public
                                            interest or subject to the exercise
                                            of public authority performed by the
                                            administrator, or
                                        </li>
                                        <li>
                                            necessity of processing for the
                                            purposes of the legitimate interests
                                            pursued by the administrator or a
                                            third party.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            against processing of personal data for statistical
                            purposes or for the purposes of historical or
                            scientific research, for reasons caused by their
                            specific situation, unless data processing is
                            subject to the exercise of public authority.
                        </li>
                    </ul>
                </Accordion>
                <Accordion
                    number={9}
                    title={'Complaint with a supervisory authority'}
                >
                    <ul>
                        <li>
                            The data subject has the right to lodge a complaint
                            with a supervisory authority – the President of the
                            Office for Personal Data Protection (UODO
                            President).{' '}
                        </li>
                        <li>
                            More information about the right to lodge a
                            complaint available at:{' '}
                            <a
                                href="https://uodo.gov.pl/pl/504/2246"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://uodo.gov.pl/pl/504/2246
                            </a>
                        </li>
                    </ul>
                </Accordion>
                <Accordion number={10} title={'Safety measures'}>
                    The necessary safety measures, which are implemented to the
                    Website, aim at protecting personal data that is beyond the
                    administrator’s control. In particular, such safety measures
                    include cryptographic security of personal data transfer
                    (the SSL protocol).
                </Accordion>
                <Accordion number={11} title={'Cookies'}>
                    <ul>
                        <li>
                            As the Website’s content is shared, the
                            administrator uses cookies, i.e. I.T. records, in
                            particular – text files containing information
                            recorded by the servers on your terminal device (
                            laptop, tablet, iPad, smartphone). The servers are
                            able to record that information from your device
                            every time the device connects with the server.
                            Usually cookies store information such as: a domain
                            name, the maximum storage time on user’s device, and
                            a unique identifier. Apart from cookies, the Website
                            may be using other technologies which function
                            similar, or identical to cookies. By the provisions
                            of this chapter, information regarding cookies is
                            also applicable to other, similar technologies.
                        </li>
                        <li>
                            Within the Website the administrator can use the
                            following cookies types: <br />
                            <ul>
                                <li>
                                    allowing use of services available on the
                                    Website,
                                </li>
                                <li>ensuring security, </li>
                                <li>
                                    gathering statistical information on the
                                    manner of using the Website by the users,
                                </li>
                                <li>
                                    remembering your Website settings, and
                                    allowing to personalize the Website’s
                                    interface for your use for example, your
                                    choice of language or region, font size,
                                    user interface etc.,
                                </li>
                                <li>
                                    supplying advertising content that meets
                                    your individual interests.
                                </li>
                                <br />
                            </ul>
                        </li>
                        <li>
                            The administrator points out that within the scope
                            of the provisions of clause 2 (c) and (d), we use
                            Google LLC cookies (related to Google Analytics) on
                            the Website. Using these services is related to the
                            implementation of Google tracking code within the
                            code of the Website.
                        </li>
                        <li>
                            The information about using cookies is displayed
                            when visiting the Website. Accepting and closing the
                            information means that the user agrees to use
                            cookies in accordance with the provision of this
                            Privacy Policy. This agreement can always be
                            withdrawn by deleting cookies files from your device
                            and changing cookies settings in your web browser.
                            The cookies settings can be changed in such a way
                            that in the future automatic handling of cookies can
                            be locked in any browser settings, or that you will
                            be notified each time on their being placed on your
                            device. Detailed information on the possibility and
                            the ways of using cookies is available in your
                            software’s settings section (the browser). For your
                            convenience, the following pointers are listed
                            below: <br />
                            <ul>
                                <li>Opera browser</li>
                                <li>Firefox browser</li>
                                <li>Chrome browser</li>
                                <li>Internet Explorer browser</li>
                                <li>Safari browser</li>
                            </ul>
                        </li>
                        <li>
                            If user decides not to change cookie-related
                            settings, it means that cookies files will be
                            included on your device. Additionally, it means that
                            the administrator can store information on your
                            device and get access to this information.
                        </li>
                        <li>
                            Disabling cookies might cause impediments when using
                            some services on the Website, especially services
                            that require signing in (Parent Zone). Blocking
                            cookies acceptance on your device will not cause
                            lack of reading or viewing content placed on the
                            Website with exclusion of the content that requires
                            that the user is signed in.
                        </li>
                    </ul>
                    <CookiesTable />
                </Accordion>
                <Accordion
                    noMarginBottom
                    number={12}
                    title={'Changing Privacy Policy'}
                >
                    <ul>
                        <li>
                            This Privacy Policy can be changed or amended by the
                            administrator at any time. Users will be notified
                            about any change or amendment via the Website.
                        </li>
                        <li>
                            This Privacy Policy shall enter into force from 25
                            May 2018.
                        </li>
                    </ul>
                    <Line />
                </Accordion>
                <UpdateText>Last update: 20 Feb 2024</UpdateText>
            </ContentWrapper>
        </Wrapper>
    );
};
