import { CommonMargin } from 'components/_layout/CommonMargin';
import React from 'react';
import { PrivacyPolicy } from 'sections/PrivacyPolicy/PrivacyPolicy';

export default () => {
    return (
        <CommonMargin>
            <PrivacyPolicy />
        </CommonMargin>
    );
};
