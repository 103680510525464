import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { H2, SectionWrapper } from 'styles/Typography.styled';

export const Wrapper = styled(SectionWrapper)`
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    padding: 100px 20px;

    ${mediaQueries.md} {
        padding: 60px 20px;
    }

    ${mediaQueries.xs} {
        padding: 24px 20px;
    }
`;

export const SectionTitile = styled(H2)`
    line-height: 54px;
    margin-bottom: 48px;

    ${mediaQueries.sm} {
        margin-bottom: 42px;
    }
    ${mediaQueries.xs} {
        margin-bottom: 24px;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Line = styled.div(
    ({ theme: { colors } }) => css`
        border-bottom: 1px solid ${colors.border};
        display: flex;
        width: 100%;
    `,
);

export const UpdateText = styled.p(
    ({ theme: { fontSizes, colors, fontWeight, fontFamily } }) => css`
        color: ${colors.grey};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f15};
        font-weight: ${fontWeight.regular};
        line-height: 32px;
        text-align: right;

        ${mediaQueries.sm} {
            font-size: ${fontSizes.f14};
        }
    `,
);
