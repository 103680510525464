import React, { useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import {
    InsideTitleWrapper,
    Number,
    Title,
    TitleWrapper,
    Wrapper,
    Arrow,
    ContentWrapper,
} from 'src/components/_universal/Accordion/Accordion.styled';

export interface Props {
    children: React.ReactNode;
    isOpen?: boolean;
    noMarginBottom?: boolean;
    number: number;
    title: string;
}

export const Accordion = ({
    children,
    isOpen,
    noMarginBottom,
    number,
    title,
}: Props) => {
    const [isSlideDown, setIsSlideDown] = useState(
        isOpen === true ? isOpen : false,
    );

    const handleAccordionOpen = () => {
        setIsSlideDown(!isSlideDown);
    };

    return (
        <Fade triggerOnce cascade fraction={0.25}>
            <Wrapper noMarginBottom={noMarginBottom}>
                <TitleWrapper onClick={handleAccordionOpen}>
                    <InsideTitleWrapper>
                        <Number>§ {number}</Number>
                        <Title>{title}</Title>
                    </InsideTitleWrapper>
                    <Arrow isOpen={isSlideDown} />
                </TitleWrapper>
                <ContentWrapper isOpen={isSlideDown}>{children}</ContentWrapper>
            </Wrapper>
        </Fade>
    );
};
