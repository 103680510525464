export const functionalCookiesData = [
    {
        subCategory: 'Codahead.com',
        cookiesUsed: '1st party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Google.com',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Hotjar',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'HR',
        cookiesUsed: 'form /application/',
        lifespan: '365 days',
    },
    {
        subCategory: 'GTM',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
];

export const analyticsCookies = [
    {
        subCategory: 'Google.com',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Codahead.com',
        cookiesUsed: 'first party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Dribble',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Facebook',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Apollo',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Hubspot',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Hotjar',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Design Rush',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Twitter',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Google Analytics',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Google Ads',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Tag Manager',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Instagram',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Clutch',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Uplead',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Youtube',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'HootSuite',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Quora',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
    {
        subCategory: 'Doubleclick',
        cookiesUsed: '3rd party',
        lifespan: '365 days',
    },
];
