import styled, { css } from 'styled-components';
import { mediaQueries } from 'shared/breakpoints';
import ArrowLeft from 'assets/images/_infoGraphics/arrowCarouselLeft.svg';

export const Wrapper = styled.div<{ noMarginBottom?: boolean }>(
    ({ noMarginBottom }) => css`
        display: flex;
        flex-direction: column;
        margin-bottom: ${noMarginBottom ? '0' : '28'}px;
        width: 100%;
    `,
);

export const TitleWrapper = styled.div(
    ({ theme: { colors } }) => css`
        border-bottom: 1px solid ${colors.border};
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding-bottom: 18px;
    `,
);

export const InsideTitleWrapper = styled.div`
    display: flex;
`;

export const Number = styled.p(
    ({ theme: { colors, fontSizes } }) =>
        css`
            color: ${colors.white};
            font-size: ${fontSizes.f20};
            line-height: 28px;
            margin-right: 24px;
            white-space: nowrap;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f18};
            }
        `,
);

export const Title = styled.h4(
    ({ theme: { colors, fontSizes, fontWeight } }) =>
        css`
            color: ${colors.white};
            font-size: ${fontSizes.f17};
            font-weight: ${fontWeight.regular};
            line-height: 28px;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f16};
            }
        `,
);

export const Arrow = styled.div<{ isOpen: boolean }>(
    ({ isOpen }) => css`
        height: 40px;
        transform: rotate(${isOpen ? '90deg' : '-90deg'});
        transition: all 0.6s ease-in-out;
        width: 40px;

        &:after {
            content: url(${ArrowLeft});
            height: 40px;
            width: 40px;
        }
    `,
);

const slideDownStyles = css`
    height: auto;
    display: block;
    opacity: 1;
`;

export const ContentWrapper = styled.p<{ isOpen: boolean }>(
    ({ theme: { fontSizes, colors, fontWeight, fontFamily }, isOpen }) => css`
        display: none;
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f15};
        font-weight: ${fontWeight.regular};
        height: 0;
        line-height: 28px;
        margin-top: 28px;
        opacity: 0;
        text-align: justify;
        transition: all 0.6s ease-in-out;
        ${isOpen && slideDownStyles};
        color: ${colors.text};

        ${mediaQueries.sm} {
            font-size: ${fontSizes.f14};
            text-align: left;
        }

        a {
            color: ${colors.hoverBlue};
            display: inline-block;
            font-weight: ${fontWeight.medium};
        }
        ul ul {
            list-style-type: disc;
        }
    `,
);
