import React from 'react';
import * as Common from 'components/_universal/Common';
import { mediaQueries } from 'shared/breakpoints';
import { analyticsCookies, functionalCookiesData } from 'shared/data/Cookies';
import styled, { css } from 'styled-components';
import { B2 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export const StyledTable = styled.table(
    ({ theme: { colors } }) => css`
        border-spacing: 0;
        font-size: 12px;
        border-collapse: collapse;
        width: fit-content;

        td,
        th {
            padding: 0 3px 3px 0;
        }

        th,
        tr {
            background: ${colors.background};
        }

        th {
            border-bottom: 1px solid rgba(239, 239, 244, 0.5);
            padding-bottom: 14px;
        }

        tr:first-child td {
            padding-top: 14px;
        }
        tr {
            th:first-child {
                min-width: 239px;
                ${mediaQueries.sm} {
                    min-width: auto;
                }
            }
        }
        tr {
            th:nth-child(2) {
                min-width: 180px;
                ${mediaQueries.sm} {
                    min-width: auto;
                }
            }
        }
    `,
);

export const CookiesTable = () => {
    const isMobile = useBreakpoint('sm');
    return (
        <Common.Div flex="column" mb={3.5} ml={isMobile ? 0 : 7} w="500px">
            <B2 bold mb={4}>
                Functional (Strictly necessary cookies):
            </B2>
            <StyledTable>
                <thead>
                    <tr>
                        <th>Cookies subcategories</th>
                        <th>Cookies used</th>
                        <th>Lifespan</th>
                    </tr>
                </thead>
                <tbody>
                    {functionalCookiesData.map((cd) => (
                        <tr>
                            <td>{cd.subCategory}</td>
                            <td>{cd.cookiesUsed}</td>
                            <td>{cd.lifespan}</td>
                        </tr>
                    ))}
                </tbody>
            </StyledTable>

            <B2 bold mb={3.5} mt={10}>
                Analytics & Marketing:
            </B2>

            <StyledTable>
                <thead>
                    <tr>
                        <th>Cookies subcategories</th>
                        <th>Cookies used</th>
                        <th>Lifespan</th>
                    </tr>
                </thead>
                <tbody>
                    {analyticsCookies.map((cd) => (
                        <tr>
                            <td>{cd.subCategory}</td>
                            <td>{cd.cookiesUsed}</td>
                            <td>{cd.lifespan}</td>
                        </tr>
                    ))}
                </tbody>
            </StyledTable>
        </Common.Div>
    );
};
